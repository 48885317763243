import { atom } from "jotai";
import { ReactNode } from "react";

export type ModalConfirmationProps = {
  isOpen: boolean;
  title: string;
  description: string | ReactNode;
  confirmText?: string;
  onConfirm: () => void;
  cancelText?: string;
  onCancel: () => void;
  withCancel?: boolean;
  loading?: boolean;
  illustration?: ReactNode;
  variant: "success" | "error" | "warning";
};

const modalAtom = atom<ModalConfirmationProps>({
  variant: "success",
  withCancel: false,
  loading: false,
  isOpen: false,
  onConfirm: () => {},
  onCancel: () => {},
  title: "",
  description: "",
  confirmText: "",
  cancelText: "",
  illustration: null,
});

export default modalAtom;
